@font-face {
  font-family: "LexendDeca";
  src: url(./fonts/LexendDeca-VariableFont_wght.ttf) format("woff");
}

body {
  font-family: "LexendDeca";
  background-color: var(--surface-a);
  color: var(--text-color);
  margin: 0;
}
